export const ANALYTICS_ROUTES = {
  GOOGLE_ANALYTICS: 'google-analytics',
  FACEBOOK: 'facebook',
  GOOGLE_BUSINESS_PROFILE: 'google-business-profile',
};

export const LOCAL_BOOST_ROUTES = {
  STEP_ONE: 'step-one',
  STEP_TWO: 'step-two',
};

export const ROUTES = {
  PRICING: {
    ROOT: '/pricing',
  },
  DASHBOARD: '/dashboard',
  OBJECTIVES: {
    ROOT: '/objectives',
  },
  ALERTS: {
    ROOT: '/alerts',
  },
  LOCAL_BOOST: {
    ROOT: '/local-boost',
    STEP_ONE: `/local-boost/${LOCAL_BOOST_ROUTES.STEP_ONE}`,
    STEP_TWO: `/local-boost/${LOCAL_BOOST_ROUTES.STEP_TWO}`,
  },
  ANALYTICS: {
    ROOT: '/analytics',
    GOOGLE_ANALYTICS: `/analytics/${ANALYTICS_ROUTES.GOOGLE_ANALYTICS}`,
    FACEBOOK: `/analytics/${ANALYTICS_ROUTES.FACEBOOK}`,
    GOOGLE_BUSINESS_PROFILE: `/analytics/${ANALYTICS_ROUTES.GOOGLE_BUSINESS_PROFILE}`,
  },
  KEYWORDS: {
    ROOT: '/keywords',
    INTRO: '/keywords/introduction',
  },
  ADD_WEBSITE: '/import',
  SERVICES: {
    ROOT: '/services',
  },
  SUPPORT: '/support',
  CHANNELS: '/channels',
};
